.userProfile i {
    font-size: 150px;
    line-height: 150px;
    color: #d1d2d3;
}
.userProfile .card-title {
    margin: 0;
    text-transform: uppercase;
    margin-top: 10px;
    font-weight: 400;
    font-size: 18px;
}
.userProfile {
    border-bottom: 0.0625rem solid #e1e3e5;
}
.icon-with-text i {
    background: #e4e5e7;
    color: #5c5f62;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 18px;
}


@media (max-width: 767px) {
.my_2 {
    margin: 1.2rem 0;
}
.userProfile i {
    font-size: 100px;
    line-height: 100px;
}



}