.infoColor {
	color: #4789c6;
}
.productSync .searchBar select.form-select:nth-child(2) {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border-right: var(--bs-border-width) solid var(--bs-border-color);
} 
.productSync .table .bg-info {
    background: #d0e4f0 !important;
    color: #3664ab;
    border-radius: 100px;
    font-weight: 500;
}
.synced.bg-info {
    background-color: #ffd6a4 !important;
    border: 1px solid #d7bb79;
    color: #5e4200;
    border-radius: 100px;
    font-weight: 400;
    font-size: 11px;
    padding: 5px 12px;
    min-width: auto;
}
.notsynced.bg-info {
    background-color: #D0E4F0 !important;
    border: 1px solid #94d1f5;
    color: #368BCE;
    border-radius: 100px;
    font-weight: 400;
    font-size: 11px;
    padding: 5px 12px;
    min-width: auto;
}
.productSync .rdt_TableCell .img-thumbnail {
    margin-right: 0.3rem;
}
.productSync .btn-group {
    width: 100%;
}
.selectAll {
    display: flex;
    align-items: center;
    column-gap: 10px;
    position: absolute;
    left: 180px;
}
.syncTable .rdt_TableHead .rdt_TableCol:nth-child(5), .syncTable .rdt_TableBody .rdt_TableCell:nth-child(5) {
    text-align: center;
    justify-content: center;
}



@media (min-width: 768px) and (max-width: 991px) {
.productSync .rdt_TableHeader>div {
    flex: 1 0 auto;
}
    
}