.text14 {
    font-size: 14px;
}
.productDetail {
    min-height: calc(100vh - 120px);
}
.backArrow {
    font-size: 1.8rem;
    border: 1px solid rgba(186, 191, 195, 1);
    width: 36px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 0.2rem;
    color: rgba(92, 95, 98, 1);
    margin-right: 0.5rem;
}
.backArrow:hover {
    color: rgba(32, 34, 35, 1);
}
.btn.addBtn:hover {
    color: #1f5199;
    text-decoration: underline;
}
.dropzone {
    position: relative;
    border: 1px dashed #cbd5e1; 
    border-radius: 0.4rem;
    padding: 25px 15px;
}
.dropzone input {
    position: absolute;
    inset: 0;
    opacity: 0;
}
.dropzone button.btn-primary {
    background-color: rgba(229, 239, 253, 1);
    border: 0;
    color: rgba(31, 81, 153, 1);
    font-size: 12px;
    padding: 5px 12px;
    margin-bottom: 0.2rem;
}
.upload {
    position: relative;
    border: 0.0625rem solid #e1e3e5;
    border-radius: 0.25rem;
    overflow: hidden;
}
.upload i {
    font-size: 80px;
    line-height: 80px;
    color: #5c5f62;
}
.upload input {
    position: absolute;
    inset: 0;
    opacity: 0;
}
.variants tr td {
    vertical-align: middle;
}
.variants tr td:last-child {
    text-align: right;
}
.productDetail ol {
    padding-left: 1rem;
    margin-bottom: 0.7rem;
    row-gap: 10px;
}
.productDetail ol li.list-group-item {
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    border-style: dashed;
    background-color: #fbfbfb;
}
.productDetail ol .btn-group {
    column-gap: 11px;
    transition: 0.3s;
    opacity: 0;
}
.productDetail ol li:hover .btn-group{
    opacity: 1;
}
.productDetail ol .btn-group button.btn {
    padding: 0;
    border: 0;
}
.productDetail .list-group-numbered>.list-group-item::before {
    position: absolute;
    left: -20px;
}
.productDetail ol .btn-group button.btn .bi-trash {
    color: #cd2323;
}
.productDetail .list-group-item+.list-group-item {
    border-top-width: 1px;
}
.productDetail ol .btn-group button.btn:active {
    background: transparent;
}
.thumbnailCards {
    display: inline-block;
    width: 100%;
}
.thumbnailCards .img-thumbnail {
    float: left;
    margin: 4px;
}
.variants table tr td {
    padding-left: 1rem;
    padding-right: 1rem;
}
.productDetail textarea.form-control, .productDetail input.form-control {
    font-size: 14px;
}
.schedule input.form-control:focus {
    box-shadow: none;
}

.schedule input.form-control[type="date"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.schedule input.form-control[type="time"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.posterVariant {
    border: 0.0625rem solid #e1e3e5;
    border-radius: var(--bs-border-radius);
    height: 50px;
    width: 50px;
    text-align: right;
    margin-right: 5px;
     position: relative;
}
.poster {
    border: 0.0625rem solid #e1e3e5;
    border-radius: var(--bs-border-radius);
    height: 200px;
    text-align: right;
    position: relative;
}
.poster button, .posterVariant button {
    background: transparent;
    border: 0;
    padding: 0;
    color: red;
    opacity: 0.7;
    position: absolute;
    top: 3px;
    right: 3px;
}
.poster button:hover, .posterVariant button:hover {
    opacity: 1;
}
.poster button:active, .poster button:hover, .posterVariant button:active, .posterVariant button:hover {
    background-color: transparent;
    color: red;
}
.setDescription .modal-dialog {
    max-width: 40rem;
}
.productDetail .bg-info {
    background: #f4f5bb !important;
    color: #6e6f1d !important;
    border: 1px solid #dadba3;
    font-weight: 400;
}
.choose-category .dropdown-trigger {
  border-radius: 0.25rem;
}
.choose-category .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}
.choose-category .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}
.choose-category .toggle.collapsed::after {
  content: "\f067";
}
.choose-category .toggle.expanded::after {
  content: "\f068";
}
.choose-category .root {
  padding: 0px;
  margin: 0px;
}


@media (max-width: 767px) {
.mx_0 {
    margin: 0 !important;
}
div .title {
    font-size: 1rem;
}
.mb_3 {
    margin-bottom: 1rem;
}
.schedule .col-md-6.ps-0 {
    padding-left: calc(var(--bs-gutter-x)* .5) !important;
    margin-top: 0.7rem;
}
.schedule .col-md-6.pe-0 {
    padding-right: calc(var(--bs-gutter-x)* .5) !important;
}




}