.searchBar input.form-control {
    font-size: 13px;
}
.searchBar span.input-group-text {
    background-color: transparent;
}
.searchBar input.form-control:focus {
    box-shadow: none;
    border-color: #ddd;
}
.searchBar select.form-select {
    border-radius: 0;
    color: #212529;
    font-size: 13px;
    border-right: 0;
    min-height: 2.4rem;
} 
.searchBar select.form-select:nth-child(1) {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}
.searchBar select.form-select:nth-child(3) {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border-right: var(--bs-border-width) solid var(--bs-border-color);
}
.searchBar .btn-group button.btn {
    margin: 0 0 0 0.7rem !important;
    border: var(--bs-border-width) solid var(--bs-border-color);
    color: #212529;
    font-size: 13px;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    min-width: 70px;
}
.searchBar select.form-select:focus {
    box-shadow: none;
    border-color: #ddd;
}
.table tbody tr td {
    vertical-align: middle;
}
.productList .table tbody tr td {
    color: #6d7175;
}
.table tbody tr td a {
    color: #000;
    text-decoration: none;
    transition: 0.3s;
}
.table tbody tr td a:hover {
    color: #4282b9;
}
.productList .table tbody tr td img.img-thumbnail {
    padding: 0;
    border-radius: 0.2rem !important;
    width: 44px;
    height: 44px;
    object-fit: contain;
}
.product-table .rdt_TableHeader {
    overflow: visible;
}
.product-table .rdt_TableHeader>div {
    height: 85%;
}
.product-table .rdt_TableHeader>div>div {
    font-size: 16px;
}
.rdt_TableCell {
    padding-top: 8px;
    padding-bottom: 8px;
}
.productList .tab-content a {
    text-decoration: none;
    color: #222;
}
.moreAction {
    position: absolute;
    left: 170px;
}
.width40 .modal-dialog {
    max-width: 40rem;
}
.moreAction .dropdown-menu button {
    font-size: 14px;
}
.rdt_TableCell .img-thumbnail {
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
}
.productList .tab-content .bg-info {
    background: #f4f5bb !important;
    color: #6e6f1d;
/*    border: 1px solid #dadba3;*/
    border-radius: 100px;
    padding: 7px 12px;
    font-weight: 400;
    font-size: 12px;
}
span.bg-success {
    background: #affebf !important;
    color: #014b40;
/*    border: 1px solid #93d7a1;*/
    font-weight: 400;
    font-size: 12px;
    border-radius: 100px;
    padding: 7px 12px;
}
span.bg-info {
    font-weight: 400;
    font-size: 12px;
    border-radius: 100px;
    padding: 7px 12px;
}
span.badge.bg-danger {
    border-radius: 100px;
    padding: 7px 12px;
    font-weight: 400;
    font-size: 12px;
}
span.badge {
    min-width: 75px;
}
.card .rdt_TableHead {
    color: #000;
    font-size: 13px;
}
.productTable .rdt_TableHead .rdt_TableCol:nth-child(2), .productTable .rdt_TableBody .rdt_TableCell:nth-child(2), .productTable .rdt_TableHead .rdt_TableCol:nth-child(3), .productTable .rdt_TableBody .rdt_TableCell:nth-child(3), .productTable .rdt_TableHead .rdt_TableCol:nth-child(6), .productTable .rdt_TableBody .rdt_TableCell:nth-child(6) {
    text-align: center;
    justify-content: center;
}
.productTable .rdt_TableHead .rdt_TableCol:nth-child(2), .productTable .rdt_TableBody .rdt_TableCell:nth-child(2) {
    min-width: 100px;
    max-width: 100px;
}
.rdt_TableCol input[type="checkbox"] {
    width: 15px;
    height: 15px;
}
.rdt_TableCell input[type="checkbox"] {
    width: 15px;
    height: 15px;
}
.choose-category .dropdown-trigger {
  border-radius: 0.25rem;
}
.choose-category .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}
.choose-category .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}
.choose-category .toggle.collapsed::after {
  content: "\f067";
}
.choose-category .toggle.expanded::after {
  content: "\f068";
}
.choose-category .root {
  padding: 0px;
  margin: 0px;
}


@media (min-width: 768px) and (max-width: 991px) {
.rdt_TableHeader>div {
    flex: initial;
}
.searchBar .row {
    flex-direction: column;
    row-gap: 10px;
}
.searchBar .row .col-sm-6 {
    width: 100%;
}

}

@media (max-width: 767px) {
.rdt_TableHeader>div {
    flex: initial;
}
.searchBar .row {
    row-gap: 10px;
}
.searchBar .btn-group button.btn {
    margin: 0 0 0 0.4rem !important;
    font-size: 12px;
}
.searchBar select.form-select {
    font-size: 12px;
    background-size: 10px;
    padding-right: 1.5rem;
}
.flexDirection .row {
    flex-direction: column;
}
span.synced.bg-info {
    font-size: 9px;
}
.selectAll {
    position: static;
    flex-direction: column;
}
.selectAll label.form-check-label {
    font-size: 14px;
}




}