.text13 {
    font-size: 13px;
}
.title {
    font-size: 1.25rem;
    font-weight: 500;
}
.gap12 {
    column-gap: 12px;
}
.cardTitle {
    padding: 1.25rem;
    border-bottom: 0.0625rem solid #e1e3e5;
}
.cardStore {
    padding: 1.25rem;
}
.edit {
    text-decoration: none;
    transition: 0.3s;
    color: #2c6ecb;
    font-size: 14px;
}
.edit:hover {
    text-decoration: underline;
    color: rgba(31, 81, 153, 1);
}
.basicText h6 {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
}
.cancel {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(32, 34, 35, 1);
    border: 1px solid rgba(186, 191, 195, 1);
    text-decoration: none;
    border-radius: 0.25rem;
    transition: 0.3s;
}
.cancel:hover {
    background: rgba(246, 246, 247, 1);
    color: #202223;
    border-color: rgba(186, 191, 195, 1);
}
.cardStore .form-control,.cardStore .form-select {
    font-size: 14px;
    padding: .475rem .75rem;
}
.cardStore .form-control:focus, .cardStore .form-select:focus {
    outline: none;
    box-shadow: none;
    border-color: #cfcfcf;
}
.basicText h6 {
    margin: 5px 0 0;
    font-size: 14px;
    line-height: 16px;
    word-break: break-word;
}


@media (max-width: 767px){
.storeBtn .btn-primary {
    margin-left: 4px !important;
}
button.btn-primary {
    padding: 0.4375rem 0.7rem;
    font-size: 13px;
}
.basicText h6 {
    font-size: 13px;
    line-height: 20px;
    word-break: break-word;
}
.gap12 {
    grid-column-gap: 8px;
    column-gap: 8px;
}
.align-items-center.alignTop {
    align-items: flex-start !important;
}


}