body {
  font-size: .875rem;
}
.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/* Navbar */

.nav-avatar {
  width: 32px;
  height: 32px;
}

/* Sidebar */

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

#sidebarMenu {
    background-color: #f6f6f7 !important;
}
.sidebar .nav {
    padding: 0 0.75rem;
}
.sidebar .nav  li {
    margin: 1px 0;
}
.sidebar .nav a.nav-link {
    padding: 5px 8px;
    border-radius: 4px;
    transition: 0.3s;
    position: relative;
    font-size: 14px;
    color: #202223;
}
.sidebar .nav a.nav-link.active, .sidebar .nav a.nav-link.active:hover  {
    background: #edeeef;
    color: rgb(66, 130, 185);
}
.sidebar .nav a.nav-link:hover {
    background: #f1f2f3;
    color: #202223;
}
.sidebar .nav>li>a.nav-link:after {
    width: 3px;
    content: '';
    height: 100%;
    position: absolute;
    background: rgb(66, 130, 185);
    top: 0;
    left: -12px;
    opacity: 0;
}
.sidebar .nav>li>a.nav-link.active:after {
  opacity: 1;
}
.sidebar .nav a.nav-link svg {
    margin-right: 8px;
    display: inline-block;
    vertical-align: sub;
    fill: #5c5f62;
}
.sidebar .nav a.nav-link.active svg {
    fill: rgb(66, 130, 185);
}
.sidebar .nav li button {
    background: transparent;
    position: absolute;
    border: 0;
    padding: 0;
    top: 5px;
    right: 0;
    font-size: 15px;
    color: #313131;
    width: 30px;
}
.sidebar .nav li ul.dropdown-menu {
    position: static !important;
    transform: initial !important;
    border: 0;
    background: transparent;
    padding: 2px 0 0 28px;
}