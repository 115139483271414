@import "../other/other-navbar.css";
:root {
 --pink: #bc27bc;
 --white: #fff;
}
.togglerBtn {
  display: none;
}
.nav-avatar-dropdown {
  border-style: hidden;
  background-color: transparent;
}
nav.navbar {
    background-color: #3d3e40;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    display: block;
}
ul.menu {
    white-space: nowrap;
    column-gap: 24px;
}
.menu li a {
    padding: 10px 3px 10px;
    margin-top: 18px;
    border-bottom: 3px solid transparent;
    color: var(--white);
    display: block;
    font-size: 16px;
    line-height: 16px;
    transition: 0.3s;
    font-family: "Inter", sans-serif !important;
    letter-spacing: 0.5px;
    font-weight: 500;
}
.menu li a.active, .menu li a:hover {
    background: linear-gradient(to bottom, #3d3e40 0%, #464749 100%);
    color: var(--white);
}
.menu li:first-child a.active, .menu li:first-child a:hover {
    border-bottom: 3px solid #008dd8;
}
.menu li:nth-child(2) a:hover, .menu li:nth-child(2) a.active {
    border-bottom: 3px solid var(--pink);
}
.menu li a:focus {
    color: #fff;
}
.merchant-heading {
    margin: 0;
    color: #bcbcbc;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-family: "Inter", sans-serif !important;
}

.userIcon {
    background: #e8f3ee;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 100px;
    font-size: 12px;
    color: #57585b;
    line-height: 27px;
    border: 2px solid #bed2c9 !important;
    padding: 0 !important;
    font-family: "Inter", sans-serif !important;
    margin-right: 0.5rem;
}
.navbar-nav  li button.nav-avatar-dropdown {
    color: var(--white);
    font-size: 12px;
    font-family: "Inter", sans-serif !important;
    letter-spacing: 0.5px;
}
.userDropdown ul {
    justify-content: flex-end;
}
.mobileView {
    display: none;
}
.menu ul.nav {
    margin-left: -20px;
}
.storeDeactivated {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -22px;
}
.storeDeactivated .col-md-12 {
    padding: 0.5rem;
}
.storeDeactivated div[role="alert"] {
    background: #5c5f62;
    color: #fff;
    border-color: #1d1d1d;
}
.storeDeactivated div[role="alert"]  a {
    color: #fff;
}

.desktopView h3.merchant-heading {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    top: 21px; z-index: -1;
}


@media (max-width: 767px) {
nav.navbar.sticky-top {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}    
.togglerBtn {
    display: block;
}
.menu li a {
    margin-top: 10px;
}
.merchant-heading {
    font-size: 14px;
    letter-spacing: 0;
    margin-top: 0rem;
}
.togglerBtn button.navbar-toggler {
    border: 0;
    padding: 0;
    margin: 0.5rem 0;
}
#sidebarMenu.sidebar {
    top: 0;
    padding-top: 60px;
}
.dashboard-page .mx-5.pt-5, .productList .mx-5.pt-5  {
    margin: 0 !important;
    padding-top: 2rem !important;
}
.merchantCard.border-l-green {
    margin: 1rem 0;
}
.setupHolder .pe-0.col-sm-4 {
    padding-right: calc(var(--bs-gutter-x) * .5) !important;
}
ul.menu {
    grid-column-gap: 15px;
    column-gap: 15px;
}
.togglerBtn button.navbar-toggler:focus {
    box-shadow: none;
}
.togglerBtn i.bi-x-lg, .togglerBtn button[aria-expanded="true"] .bi-list {
    display: none;
}
.togglerBtn button[aria-expanded="true"] i.bi-x-lg {
    display: block;
}
.togglerBtn i {
    font-size: 26px;
}
.desktopView {
    display: None;
}
.mobileView {
    display: block;
}
.mobileNav {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-top: 10px;
}
.mobileView h3.merchant-heading {
    text-align: right;
    margin-top: 0.5rem;
}
.mobileNav .navbar-nav .dropdown-menu {
    position: absolute;
    left: 0;
}
.menu ul.nav {
    justify-content: flex-end;
}


}