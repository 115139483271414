.text15 {
	font-size: 15px;
}
.blueColor {
    color: #4789c6;
}
.delPayment {
    color: #585858;
    transition: 0.3s;
    min-height: auto;
    height: 25px;
    padding: 0;
}
.delPayment:hover {
	color: #000;
}
.tabs {
    padding: 0 1rem;
    border-bottom: 1px solid #dee2e6;
}
.tabs .nav-item .nav-link {
    border: 0;
    position: relative;
    padding: 15px 17px;
    color: #6d7175;
    transition: 0.3s;
}
.tabs .nav-item .nav-link:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.1875rem; 
    border-radius: 4px 4px 0 0;
    transition: 0.3s;
}
.tabs .nav-item .nav-link.active:before, .tabs .nav-item .nav-link:hover:before {
    background-color: rgb(66 130 185);
}
.tabs .nav-item .nav-link:hover, .tabs .nav-item .nav-link.active {
	color: rgb(66 130 185);
}
.table {
    margin-bottom: 0;
}
.table>thead tr th {
    font-weight: 500;
}
.planCreate .border-bottom {
    border-color: #dee2e6 !important;
}
.sort {
    max-width: 150px;
}
.sort span {
    white-space: nowrap;
    opacity: 0.6;
    font-size: 13px;
}
.sort .form-select {
    max-width: 85px;
    font-size: 12px;
    border: 0;
}
.sort .form-select:focus {
    box-shadow: none;
    cursor: pointer;
}
.vatNumber .btn-outline-secondary {
    border-color: #cdcccc;
    color: #222;
    font-size: 14px;
    padding: 0.44rem 1.2rem;
}
.vatNumber .btn.btn-link {
    font-size: 14px;
}
.modalCard .modal-dialog {
    max-width: 40rem;
}
.billing .table>thead tr th, .billing .table>tbody tr td {
    padding: 10px 20px;
}
.delPayment:active {
    background: transparent;
}


@media (max-width: 767px){
.tabs .nav-item .nav-link {
    padding: 6px 10px;
    font-size: 13px;
}
.billing .table>thead tr th, .billing .table>tbody tr td {
    font-size: 13px;
    white-space: nowrap;
}




}