.backBtn {
    border: 1px solid rgba(186, 191, 195, 1);
    padding: 0 0.2rem;
    border-radius: 0.2rem;
    font-size: 2rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    color: rgba(109, 113, 117, 1);
    transition: 0.3s;
}
.backBtn:hover {
    background: #fff;
}
.chooseCard {
    border-radius: 0.375rem;
    border: 1px solid #dee2e6;
}
.chooseCard form {
    background-color: #ebf5fa;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem 1rem 1.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.chooseCard .text-end a {
    text-decoration: none;
    color: rgba(44, 110, 203, 1);
    transition: 0.3s;
}
.chooseCard .text-end a:hover {
    text-decoration: underline;
}
.fw-500 {
    font-weight: 500;
}
