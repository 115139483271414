html,
body {
  height: 100%;
}
.bgLight {
    background-color: #f3f4f6;
}
.loginCard {
    background-color: #fff;
    flex-direction: column;
    width: 25rem;
    margin: 0 auto;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-radius: 0.5rem;
    padding: 1.5rem;
}
.loginCard h3 {
    font-size: 20px;
    color: rgb(32, 34, 35);
    margin: 0 0 1.6rem;
}
.loginCard label.form-label {
    position: static;
    font-size: 14px;
    font-weight: 400;
    color: rgb(55 65 81);
    margin-bottom: 0;
    padding: 0;
    height: auto;
}
.loginCard input.form-control {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    height: 36px;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.075);
    font-size: 14px;
    min-height: auto;
    padding: 0 10px!important;
}
.loginCard button.btn {
    border-radius: 0.375rem;
    height: 36px;
    background: #4080bc;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 500;
}
.loginCard p a {
    margin-left: 5px;
}
.loginCard a.logo {
    transition: 0.3s;
    margin: 15px 0 28px;
    display: inline-block;
}
.loginCard a:hover {
    color: #111;
}
.resetLink {
  font-size: 13px;
}
.logo img {
    max-width: 130px;
    filter: brightness(0.2);
}
.creating-account label {
    font-size: 12.5px;
}
.alert-danger {
    text-align: left;
    color: #dc2626;border-color:#f5c2c7;
}
.alert-success {
    text-align: left;
    color: #0f5132;border-color:#badbcc;
}