.storeModal .modal-dialog {
    max-width: 55rem;
}
.storeModal .modal-content {
    background: #232323;
    color: #fff;
}
.storeModal .modal-header, .storeModal .modal-footer {
    border-color: #777777;
}
.storeModal .modal-header .btn-close {
    filter: invert(1);
}
.storeModal .modal-body .img-thumbnail {
    border-radius: 0;
    padding: 0;
}
.storeModal .modal-body span {
    background-color: #fff;
    width: 100%;
    display: block;
    color: #252525;
    padding: 0.3rem;
    font-size: 12px;
}
.storeModal button.cancel {
    background-color: #535353;
    border-color: #535353;
    color: #fff;
}
.storeModal button.cancel:hover, .storeModal button.cancel:focus {
    background-color: rgb(83 83 83 / 60%);
}
.add-new-btn button:active {
    color: rgba(255, 255, 255, 0.8) !important;
}