@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
html, body {
    font-family: "Inter", sans-serif;
}
:root {
 --dark: #3d3e40;
 --dark2: #343537;
 --light-gray: #535353;
 --pink: #bc27bc;
 --black: #000;
 --white: #fff;
 --white-bg: #fff;
 --light-white: #e4e4e4;
}
.text12 {
    font-size: 12px;
}
.text17 {
    font-size: 17px;
}
.text20 {
    font-size: 20px;
}
body {
    background-color: #f3f4f6;
}
.maxWidth .modal-dialog {
    max-width: 650px;
}
.btn-primary {
    background-color: #4080bc;
    border-color: #4080bc;
    font-size: 14px;
    padding: 0.4375rem 1rem;
}
.btn-primary:hover {
    background-color: #1f5199;
}
.border-l-yellow {
    border-color: #f2ec3a !important;
}
.border-l-green {
    border-color: #46ad30 !important;
}
.border-l-blue {
    border-color: #4675bf !important;
}
.border-l-red {
    border-color: #E3334B !important;
}
#sidebarMenu + main {
    padding: 0 !important;
}
.merchantCard {
    background-color: var(--white-bg);
    box-shadow: 0px 2px 3px rgba(23, 24, 24, 0.05);
    border-radius: 0.5rem;
    padding: 0.6rem 0.5rem 1.5rem 1rem;
    border-left: 2px solid transparent;
    text-align: right;
}
.infoIcon {
    background: #e4e5e7;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: inline-block;
}
.infoIcon img {
    opacity: 0.6;
    width: 18px;
    height: 18px;
}
.infoIcon {
    background: #e4e5e7;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    line-height: 28px;
}
.infoText {
    text-align: left;
    padding-top: 0.3rem;
}

.card {
    box-shadow: 0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(23, 24, 24, 0.05);
    border: 0;
}
.setupGuide {
    padding: 1.25rem;
    border-bottom: 0.0625rem solid #e1e3e5;
}
.nav-pills .nav-item a {
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
    font-size: 15px;
    color: #5f6367;
    width: 100%;
    padding: 1.5rem;
    border-right: 0.0625rem solid #e1e3e5;
    border-bottom: 0.0625rem solid #e1e3e5;
    border-left: 3px solid transparent;
    background: #fafbfb;
    border-radius: 0;
    font-weight: 500;
}
.nav-pills .nav-item a svg {
    width: 24px;
    height: 25px;
    fill: #5f6367;
    margin-right: 16px;
}
.nav-pills .nav-item a.selected svg {
    fill: #639f3a;
}
.setupHolder .nav-pills .nav-link img {
    display: inline-block;
    width: 24px;
    margin-right: 1rem;
}
.setupHolder .nav-pills .nav-link:hover, .setupHolder .nav-pills .nav-link.active {
    color: #4485c9;
    border-left: 3px solid #4485c9;
    background: #fff;
}
.setupHolder .nav-pills .nav-link:hover svg, .setupHolder .nav-pills .nav-link.active svg {
    fill: #4485c9;
}
.setupHolder .nav-pills .nav-link.selected svg, .setupHolder .nav-pills .nav-link.selected:hover svg {
    fill: #639f3a;
}
.setupHolder .nav-pills .nav-link.selected, .setupHolder .nav-pills .nav-link.selected:hover {
    color: #5f6367;
}
.setupHolder .nav-pills .nav-link.active {
    border-right: transparent;
}
.setupHolder .tab-content {
    padding: 1.5rem 2rem;
    border-left: 0.0625rem solid #e1e3e5;
    margin-left: -1px;
}
.statusInfo {
    background-color: rgba(235, 249, 252, 1);
    padding: 1.5rem;
    border-radius: 0.5rem;
}
.vrStore form .d-flex {
    column-gap: 20px;
}
.vrStore form label {
    font-weight: 500;
    padding: 0 00 .3rem;
}
.vrStore form small {
    font-size: 15px;
}
.vrStore form .form-check-input {
    width: 1.3em;
    height: 1.3em;
    position: absolute;
    opacity: 0;
}
.vrStore form  svg {
    width: 1.3em;
    height: 1.3em;
}
.vrStore form input[type="radio"] + svg .unchk {
    display: none;
}
.vrStore form input[type="radio"]:checked + svg .unchk {
    display: block;
}
.shopifyLogo img {
    width: 80px;
}
.setupHolder .searchBar {
    margin-bottom: 0.5rem;
}
.setupHolder .searchBar .row {
    flex-direction: column;
    row-gap: 10px;
}
.setupHolder .searchBar .col-sm-6, .setupHolder .searchBar .btn-group {
    width: 100%;
}
.setupHolder .searchBar .btn-group .form-select:nth-child(2) {
    border-right: 1px solid #ddd;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.setupHolder .table>thead tr th:nth-child(2) {
    width: 200px;
}
.setupHolder .table>thead tr th:nth-child(3) {
    width: 50px;
}
.setupHolder .table>thead tr th {
    font-weight: 500;
    font-size: 12px;
}
.setupHolder .table>tbody tr  td {
    font-size: 12px;
    line-height: 18px;
}
.setupHolder .table>tbody tr td:nth-child(2) {
    display: flex;
    column-gap: 5px;
    align-items: center;
}
.setupHolder .table .img-thumbnail {
    border-radius: 0;
    padding: 0;
    width: 36px;
    height: 36px;
    object-fit: contain;
}
.setupHolder .table .bg-info {
    background: #d0e4f0 !important;
    color: #3664ab;
    border-radius: 100px;
    font-weight: 500;
}
.paginations button.btn {
    font-size: 12px;
    margin: 0 0.2rem;
    border: 1px solid #ddd;
    color: #3664ab;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb:hover {
    background: #7a7a7a;
}
::-webkit-scrollbar-thumb {
    background: #696969;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
::-webkit-scrollbar-track {
    background: #4a4a4a;
}
.btn:active {
    background-color: transparent !important;
    color: #000 !important;
}
.get-ready-box {
    width: 100%;
}
.guide-nav-tabs {
    max-width: 260px;
}
.dashboard-page {
    min-height: calc(100vh - 120px);
}
a.infoColor:hover {
    color: #1f5199;
}
.setupHolder .rdt_Table .rdt_TableHead .rdt_TableCol:nth-child(5), .setupHolder .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(5) {
    min-width: 14%;
}
.setupHolder .get-ready-box {
    width: calc(100% - 260px);
}


@media (min-width: 992px) and (max-width: 1200px) {
body .mx_3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}
.infoText .fs-6 {
    font-size: 0.81rem!important;
}

}


@media (min-width: 768px) and (max-width: 991px) {
.m_0 {
    margin: 0 !important;
}
.full-width {
    width: 100%;
}
.nav-pills .nav-item {
    width: 100%;
}
.nav-pills .nav-item a {
    font-size: .7rem;
    padding: 1rem 0.5rem;
    border-radius: 0;
}
.setupHolder .nav-pills .nav-link img {
    width: 18px;
    margin-right: 0.3rem;
}
.userDropdown ul {
    align-items: flex-end;
}
.merchant-heading {
    font-size: 14px;
}
.userDropdown ul.dropdown-menu {
    position: absolute;
}


}