.fs-12 {
	font-size: 12px;
}
.asstesCount {
    background: #535353;
    position: fixed;
    width: calc(100% - 397px);
    z-index: 123;
}
.virtualCanvasArea {
    width: calc(100% - 327px);
}
.sidebarCanvas {
    background: #535353;
    width: 327px;
    border-left: 2px solid #343536;
    position: fixed;
    right: 0;
    height: 100vh;
}
/* .sidebarCard {
    overflow-y: scroll;
    height: 50vh;    height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
} */
.scrollbarCard { 
    height: calc(100% - 41px);
    overflow-x: hidden;
    overflow-y: scroll;
}
.canvasFrame {
    padding: 50px 8px 0px 10px;
    /* padding: 50px 8px 200px 10px; */
    background-color: #343536;
    height: auto;
}
.canvasFrame>div {
    background: #000;
}
.canvasCard { 
    background-color: #000;
    padding: 0 2rem;
}

.properties {
    background: #3d3e40;
}
.properties .btn {
    border-radius: 0;
    font-size: 11px;
    background-color: #535353;
    border: 0;
    padding: 6.5px 25px 3px 15px;
    min-width: 90px;
    text-align: left;
}
.properties .btn:active {
    background: #535353 !important;
    color: #fff !important;
}
.sidebarCard .accordion-item {
    border: 0;
    border-bottom: 1px solid #454545;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
}
.sidebarCard .accordion-item:last-child {
    border-bottom: 0;
}
.sidebarCard .accordion-item .accordion-header button {
    color: #fff;
    font-size: 11px;
    position: relative;
    padding: 15px 15px 15px 30px;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
}
.sidebarCard .accordion-item .accordion-header button::after {
    position: absolute;
    margin-left: -17px;
    background-size: 12px;
    filter: invert(1);
    width: 12px;
    height: 12px;
}
.sidebarCard .accordion-body {
    padding: 0 15px 20px 26px;
}
.sidebarCard .accordion-body .input-group label {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 0.40rem;
    margin-bottom: 0;
}
.sidebarCard .accordion-body .input-group input.form-control {
    height: 22px;
    background: #4a4a4a;
    border-radius: 3px 0 0 3px !important;
    border-color: #777777;
    padding: 5px;
    color: #fff;
    font-size: 11px;
    margin-right: 0;
}
.sidebarCard .accordion-body .input-group input.form-control:focus, .sidebarCard .accordion-body .input-group input.form-control:focus + button {
    box-shadow: none;
    border-color: #999;
}
.sidebarCard .accordion-body .input-group button {
    padding: 0;
    width: 17px;
    font-size: 10px;
    height: 22px;
    line-height: 20px;
    border-radius: 0 3px 3px 0;
    border-color: #777;
    background-color: #555555;
    color: #cccccc;
    border-left: 0;
    transition: 0.3s;
}
.sidebarCard .accordion-body .input-group button:hover {
    background-color: #696969;
}
.sidebarCard .accordion-body .input-group {
    position: relative;
}
.sidebarCard .accordion-item:first-child .accordion-header button {
    padding-top: 40px;
}
.sidebarCard .accordion-item:first-child .col-4:first-child .input-group:before {
    background: #777;
    position: absolute;
    content: "";
    height: 17px;
    width: 1px;
    left: 50%;
    top: 28px;
}
.sidebarCard .accordion-item:first-child .col-4:first-child .input-group:after {
    background: #777;
    position: absolute;
    content: "";
    height: 1px;
    width: 78px;
    left: 50%;
    top: 45px;
}
.sidebarCard .accordion-item:first-child .col-4:last-child .input-group:before {
    background: #777;
    position: absolute;
    content: "";
    height: 17px;
    width: 1px;
    left: 50%;
    top: 28px;
}
.sidebarCard .accordion-item:first-child .col-4:last-child .input-group:after {
    background: #777;
    position: absolute;
    content: "";
    height: 1px;
    width: 78px;
    right: 50%;
    top: 45px;
}
/*.sidebarCard .accordion-item .accordion-body {
    padding-bottom: 45px;
}*/
.linkIcon {
    text-align: center;
    padding-top: 13px;
    position: relative;
}
.linkIcon:after {
    background: #777;
    position: absolute;
    content: "";
    height: 10px;
    width: 1px;
    left: 50%;
    top: 3px;
}
.linkIcon button.btn {
    background-color: #4a4a4a;
    padding: 0;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border: 1px solid #777;
}
.linkIcon button.btn:hover {
    background: #696969;
}
.linkIcon button.btn:active {
    background: #696969 !important;
    border: 1px solid #777;
    color: #cbcbcb !important;
}
.sidebarCard .list-group {
    column-gap: 8px;
}
.sidebarCard .list-group-item .btn {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    background: #4a4a4a;
    color: #fff;
    padding: 0;
    border: 1px solid #777777;
    border-radius: 3px;
    transition: 0.3s;
    width: 22px;
    height: 22px;
}
.sidebarCard .list-group-item {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
}
.sidebarCard .list-group-item .btn:hover {
    background-color: #696969 !important;
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
}
.plusIcon {
    border-bottom: 1px solid #3d3e40;
    background-color: #535353;
}
.plusIcon .btn.btn-primary {
    width: 24px;
    height: 24px;
    line-height: 12px;
    color: rgb(255, 255, 255);
    background: rgb(74, 74, 74);
    padding: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(119, 119, 119);
    border-radius: 3px !important;
}
.plusIcon .btn-group {
    column-gap: 8px;
}
.plusIcon .btn.btn-primary:hover {
    background: #5a5959;
    border-color: rgb(119, 119, 119);
}
.bottomSideBar {
    border-top: 2px solid #3d3e40; height: 50vh;
}
.topSideBar {height: 50vh;}
.searchProduct h6 {
    font-size: 12px;
    font-weight: 400;
}
.searchProduct .input-group button {
  background-color: #4a4a4a;
  border-color: #696969;
  padding: 1px 10px;
  border-right: 0;
}
.searchProduct .input-group button:active {
    background-color: #4a4a4a !important;
    border-color: #696969;
}
.searchProduct .input-group button:active i {
    color: #fff !important;
}
.searchProduct .input-group input.form-control {
  background-color: #4a4a4a;
  border-color: #696969;
  border-left: 0;
  padding: 0;
  color: #fff;
  font-size: 12px;
  height: 29.62px;
}
.searchProduct .input-group button svg {
  position: relative;
  top: -1px;
}
.searchProduct .btn-check:checked + .btn,
.searchProduct .btn.active,
.searchProduct .btn.show,
.searchProduct .btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: #4a4a4a;
}
.searchProduct .input-group input.form-control:focus {
  box-shadow: none;
}
.searchProduct .input-group input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
  font-size: 12px;
}
.searchProduct .input-group input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
  font-size: 12px;
}
.searchProduct .input-group input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
  font-size: 12px;
}
.searchProduct .input-group input:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
  font-size: 12px;
}
/* .searchProduct .input-group select.form-select {
    background-size: 9px;
    background-color: #4a4a4a;
    border-color: #696969;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    cursor: pointer;
    padding: 0.295rem 2.25rem 0.295rem 0.75rem;
    background-image: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
} */
.searchProduct .input-group select.form-select {
    background-size: 9px;
    background-color: #4a4a4a;
    border-color: #696969;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    cursor: pointer;
    padding: 0.295rem 2.25rem 0.295rem 0.75rem;
    background-image: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    background-repeat: no-repeat;
    background-position: right .455rem top 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23a1a1a1' class='bi bi-chevron-down' viewBox='0 0 16 16' style='&%2310; background: %234a4a4a;&%2310;'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-size: 12px;
}
.searchProduct .input-group .position-relative:nth-child(2) select.form-select {
    border-radius: 0;
}
.searchProduct .input-group .position-relative:nth-child(3) select.form-select {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 1px solid #696969
}
.searchProduct .input-group select.form-select:focus {
  box-shadow: none;
}
.searchProduct .input-group i.bi-chevron-down {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    color: #a1a1a1;
    font-size: 12px;
}
.searchProduct .input-group .position-relative {
    flex: 1 0 0;
}
.myAssets {
    position: fixed;
    width: calc(100% - 397px);
    z-index: 123;
    bottom: 0;
    background-color: #535353;
    height: 200px;
}
.productListTable table.table tr th, .productListTable table.table tr td {
    background: #3d3e40;
    padding: 6px 12px;
    box-shadow: none;
    border: 0;
}
.productListTable .table tr {
    border-bottom: 1px solid #535353;
}
.productListTable .table-responsive {
    border-radius: 5px;
    height: 300px;
    margin: 3px;
}
.productListTable table.table tr th {
    font-size: 12px;
    position: sticky;
    top: -1px;
    padding: 12px;
}
.productListTable table.table tr th:nth-child(1), .productListTable table.table tr td:nth-child(1) {
    padding-left: 18px;
}
.productListTable table.table tr th:nth-child(3) {
    width: 80px;
}
.productListTable table.table tr td {
    font-size: 11px;
}
.productListTable table .img-thumbnail {
    background: transparent;
    padding: 0;
    aspect-ratio: 1.1;
    width: 46px;
}
.productListTable span.bg-success, .productListTable span.bg-info {
    font-size: 11px;
    min-width: auto;
}
.productListTable span.bg-info {
    background-color: #d5ebff !important;
    color: #003a5a;
}
.assetsHeading {
    padding: 12px 0 12px 16px;
}
.myProduct {
    min-height: 38px;
}
.searchProduct {
    position: relative;
    border-top: 10px solid #343536;
}
.searchProduct:after {
    border-top: 2px solid #353637;
    position: absolute;
    content: "";
    top: 38px;
    width: 100%;
}
.foldertree {
    height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-right: 3px solid #464545;
    padding-bottom: 150px;
}
.foldertree p {
    margin-bottom: 0.11rem;
    color: #fff;
    font-size: 11px;
    padding: 0.4rem 1.5rem;
    transition: 0.3s;
}
.foldertree p.selected {
    background-color: #6a6a6a;
}
.foldertree p:hover {
    background-color: #6a6a6a;
}
.foldertree p.expandedOver:hover {
    background-color: initial;
}
.foldertree p:empty {
    display: none;
}
.foldertree p img {
    margin-right: 0.3rem;
    display: inline-block;
    vertical-align: bottom;
    object-fit: contain;
}
.foldertree  p.expandedOver {
    padding: 0;
    margin: 0;
}
.foldertree > p.expandedOver>p {
    padding-left: 2.7rem;
}
.foldertree > p.expandedOver  p.expandedOver {
    padding: 0;
}
.foldertree > p.expandedOver  p.expandedOver p {
    padding-left: 3.8rem;
}
.konvajs-content, .konvajs-content canvas {
    margin: 0 auto;
    /* width: 100% !important; */
}
.productObject .sidebarCard {
    padding-bottom: 150px;
}
.productObject .sidebarCard p {
    margin: 0;
    padding: 10px 15px 10.7px 15px;
    border-bottom: 1px solid #454545;
    color: #fff;
    font-size: 11px; 
}
/* .productObject .sidebarCard p.objectTree {
    padding-top: 0;
    padding-bottom: 0;
} */
.productObject .sidebarCard .selected {
    background-color: #696969; font-weight: bold;
}
.productObject .sidebarCard p.objectTree {
    padding-top: 0px;
    padding-bottom: 0px;
    padding: 0px;
}
.productObject .sidebarCard p.objectTree p {
    padding: 10.2px 39px;
}
.productObject .sidebarCard p.objectTree 
 .objectTree {
    padding: 0;
}
.productObject .sidebarCard p.objectTree .objectTree p {
    padding: 9px 40px 8.2px;
}
.productObject .sidebarCard p img {
    width: 13px;
    height: 13px;
    margin: 0 4px 0 4px;
}
.productObject .sidebarCard p.objectTree .objectTree p img {
    height: 22px;
    background: #fff;
    padding: 2px;
    width: 35px;
    object-fit: contain;
    border: 1px solid #000;
}
.productObject .sidebarCard p:last-child {
    border-bottom: 0;
}
.asstesCount p {
    padding: 12px 16px;
    height: 40px;
}
.sidebarCard .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}
.virtualAssets, .virtualAssets>.row, .virtualCanvasArea, .canvasFrame, .canvasFrame>div {
    height: 100%;
}
.leftAssets {
    width: 290px;
}
.rightAssets {
    width: calc(100% - 290px);
}
.contentImage_holder {
    padding: 0 15px;
}

.folderCrumb {
    padding: 10px 0;
}

.folderCrumb ol li span{
    margin: 0;
    line-height: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: top;
    font-family: Verdana, sans-serif;
    font-size: 11px;
    color: #fff;
}
.folderCrumb ol li:last-child span {
    color: #b9b9b9;
}
.contentbox {
    display: flex;
}

.content_thumbnails {
    flex: 0 0 13%;
    padding: 0 2%;
    /* padding: 0 20px; */
}

.content_thumbnails p {
    cursor: pointer;
    font-size: 10px;
    text-align: center;
    margin-top: 0.5rem;
    color: #fff;
    margin:0.5rem 0 0 0;
}

.content_thumbnails img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    background-color: #fff;
    border-radius: 0.4rem;
}

@media (min-width: 1400px) and (max-width: 3500px) {
.myAssets {
    height: 330px;
}
.canvasFrame {
    padding: 50px 8px 0px 10px;
    /* padding: 50px 8px 330px 10px; */
}
.foldertree {
    padding-bottom: 30px;
}


}

@media (max-width: 767px) {
.virtualCanvasArea {
    width: 100%;
}
.searchProduct .align-items-center.row {
    flex-direction: column;
}
.sidebarCanvas {
    width: 100%;
    border-left: 0;
    margin-top: 0.5rem;
    border-top: 3px solid #464646;
}
.asstesCount {
    width: 100%;
}
.asstesCount p {
    padding: 0.5rem 1rem !important;
}
.searchProduct p {
    font-size: 12px;
    padding-bottom: 0.5rem;
}
.mb_2 {
    margin-bottom: 0.5rem;
}



}