body .border-bottom {
    border-bottom: 0.0625rem solid rgba(201, 204, 207, 1) !important;
}
.text12 {
    font-size: 12px;
}
.link {
    text-decoration: none;
    font-size: 13px;
    transition: 0.3s;
}
.paymentIcon {
    width: 60px;
}
.subscriptionPlan .link {
    color: #4789c6;
}


@media (min-width: 768px) and (max-width: 991px) {
.vcmonthly-subscription span.fs-3 {
    font-size: 18px !important;
}
    
}