main.file-explorer {
  background-color: #535353;
  height: 400px !important;
  width: 60% !important;
  color: #F5F5F5;
  font-family: "Inter", sans-serif;
}
.file-explorer .files-container .folders-preview {
  background-color: #535353;
  color: #F5F5F5;
}
.file-explorer .files-container .navigation-pane .sidebar-resize {
  border-right: 1px solid #696969;
}
.file-explorer button {
  font-family: "Inter", sans-serif;
}
.toolbar {
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  border-bottom: 1px solid #696969;
  padding: 5px 10px;
}
.toolbar .fm-toolbar {
  height: 28px;
}
.toolbar .item-action {
  background-color: #535353;
  color: #F5F5F5;
}
.toolbar .item-action:hover {
  background-color: #6A6A6A !important;
  color: #cac0c0;
}
.sb-folders-list .sb-folders-list-item:hover {
  background-color: #6A6A6A;
}
.sb-folders-list .active-list-item {
  background-color: #6A6A6A;
}
.sb-folders-list .active-list-item:hover {
  background-color: #6A6A6A;
}
.bread-crumb-container .breadcrumb {
  height: 34px;
  min-height: 34px;
  max-height: 34px;
  display: flex;
  gap: .5rem;
  border-bottom: 1px solid #696969;
  padding: 6px 0 6PX 15px;
  overflow-x: hidden;
}
.bread-crumb-container .breadcrumb .folder-name:hover {
  color: #cac0c0;
}
.files .file-item:hover {
  background-color: #6A6A6A;
}
.files .file-selected {
  background-color: #6A6A6A;
}
.files .file-selected:hover {
  background-color: #696767;
}