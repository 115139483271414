.nestable > .nestable-list {
    max-width: 650px;
}
.nestable-item-name {
    display: block;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: move;
    margin: 0 0 10px;
    background: #dbdbdb;
    padding: 1px 15px;
    position: relative;
    overflow: hidden;
        transition: 0.3s;
}
.nestable-item-name:hover {
    background-color: #fff;
}
.nestable-item-name:hover small.form-text {
    color: #5e91bf;
}
.nestable-item-name button.btn {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0 0.3rem;
    color: #5E91BD;
    font-size: 15px;
}
.collApse small.form-text {
    font-size: 0.9rem;
    color: #333;
    font-weight: 600;
}
.collApse span {
    left: 0;
    background: #859685;
    top: 0;
    bottom: 0;
    width: 30px;
    text-align: center;
    margin: -15px;
    padding: 12px 4px;
}
.collApse span i {
    margin: 0;
    filter: brightness(0.1);
    background-size: 10px;
}
.collApse span + small.form-text {
    font-size: 0.9rem;
    padding-left: 20px;
}
.nestable-item-name button.btn .bi-plus {
    font-size: 24px;
}
.minHeight {
    min-height: calc(100vh - 120px);
}


@media (max-width: 767px) {
.nestable-item-name button.btn {
    font-size: 15px;
}
.nestable ol li ol.nestable-list {
    padding: 0 0 0 30px;
}


}