.vartualSidebar#sidebarMenu {
    width: 70px;
    background-color: #3d3e40 !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
}
.virtualMain {
    width: calc(100% - 70px);
    background-color: #535353 !important;
    height: calc(100vh - 57px);
}
.virtualAssets, .solutionList {
    background: #535353;
}
.vartualSidebar a.nav-link {
    padding: 8px 6px 14px;
    text-align: center;
    color: #d3d7db;
    font-size: 12px;
    font-weight: 600;
}
.vartualSidebar a.nav-link svg {
    fill: #d3d7db;
    transition: 0.3s;
    margin-bottom: 4px;
}
.vartualSidebar a.nav-link:hover svg, .vartualSidebar a.nav-link.active svg {
    fill: #d49bf7;
}
.vartualSidebar a.nav-link:hover, .vartualSidebar a.nav-link.active {
    color: #d49bf7;
}
.vartualSidebar a.nav-link img {
    display: block;
    margin: 0 auto 2px;
    filter: invert(1);
    opacity: 0.7;
}
.vartualSidebar a.nav-link i {
    font-size: 16px;
}